import React from 'react'
import logo from '../logo.png'

export default function Footer() {
    return (
        <footer>
            <div className="footer-content">
                <img src={logo} alt="" />
                <p>products of a controlled society</p>
                <ul className="socials">
                    <a href="https://x.com/dummiesandrags"><li><i className="fab fa-twitter"></i></li></a>
                    <a href='https://www.instagram.com/dumm.rags/' target="_blank" rel="noreferrer"><li><i className="fab fa-instagram"></i></li></a>
                    {/* <a href='https://music.apple.com/ng/playlist/stay-tf-home/pl.u-55D6X1qc631rEmk' target="_blank" rel="noreferrer"><li><i class="fab fa-apple"></i></li></a> */}
                    {/* <a href=""><li><i class="fab fa-spotify"></i></li></a> */}
                </ul>
            </div>
            <div className="footer-bottom">
                <p>copyright &copy;2021 919DNR</p>
            </div>
        </footer>
    )
}
