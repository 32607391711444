import Footer from '../Components/Footer';
import Navbar from '../Components/Navbar'
import React from 'react';
import video from '../yo.MOV'
import { Link } from 'react-router-dom';
import img1 from '../1.webp'
import img2 from '../2.webp'
import img3 from '../3.webp'
import img4 from '../4.webp'
import img5 from '../5.webp'
import img6 from '../6.webp'
import slim2 from '../slim2.jpg'
import slime from '../slime.jpg'
import volt from '../volt.jpg'
import gnarlie1 from '../gnarlie2.jpg'
import gnarlie2 from '../gnarlie3.jpg'
import gnarlie3 from '../gnarlie3.jpg'
import MetaTags from '../Components/Metatags';

export default function Homescreen() {
    return <div className='ps'>
                <MetaTags title={'Home'} />
        <Navbar></Navbar>
        <main>
            {/* <h1 className='homeh1'>'FOR YOUR SANITY' COLLECTION LIVE. <Link to='/shop'>
                <span>SHOP NOW</span></Link></h1>
            <main className='homevideo'>
                <video src={video} className="videos" controls="controls" poster='../gnarlie1.jpg/' ></video>
            </main > */}
            <h1 className='homeh2'>Gallery</h1>
            <section className='gallery'>
                <img src={img2} alt="img2" className='pic'></img>
                <img src={img4} alt="img4" className='pic'></img>
                <img src={img1} alt="img1" className='pic'></img>
                <img src={img5} alt="img5" className='pic'></img>
                <img src={img6} alt="img6" className='pic'></img>
                <img src={gnarlie2} alt="slim2" className='pic'></img>
                <img src={img3} alt="img3" className='pic'></img>
            </section>
        </main>

        <Footer></Footer>
    </div >;
}
