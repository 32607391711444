import './App.css';
import Homescreen from './Screens/Homescreen';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Productscreen from './Screens/Productscreen';
import SingleProduct from './Screens/SingleProduct';
import Cartscreen from './Screens/Cartscreen';
import { useState } from 'react';
import MetaTags from './Components/Metatags';

// const reducer = (state, action) => {
//   if (action.type === 'UPDATE_CART') {
//     const carts = [...state.cart, action.payload]
//     localStorage.setItem('catrs', JSON.stringify(carts))
//     console.log(state);
//     return { ...state, cart: carts }i
//   }

// }
// const defaultstate = {
//   cart: []
// }


function App() {
  window.addEventListener("scroll", function () {
    let nav = document.querySelector("nav");
    nav.classList.toggle("sticky", window.scrollY > 0)
  })

  // const [state, dispatch] = useReducer(reducer, defaultstate)
  // const updateCart = (id, name, price, image, qty, size, hassize) => {
  //   const cart = { id, name, price, image, qty, size, hassize }
  //   dispatch({ type: 'UPDATE_CART', payload: cart })
  // }

  // localStorage.setItem('catrs', JSON.stringify([]))
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem('catrs')).length < 1 ? [] : JSON.parse(localStorage.getItem('catrs')))

  localStorage.setItem('catrs', JSON.stringify(cart))
  const updateCart = (id, name, price, image, slug, qty, size, hassize) => {
    const two = JSON.parse(localStorage.getItem('catrs'))
    setCart([...two, { id, name, price, image, slug, qty, size, hassize }])
    let newset = two.filter((pep) => pep.id !== id)
    setCart([...newset, { id, name, price, image, slug, qty, size, hassize }])
    alert(`${name} has been added to your cart`)
  }
  const title = "Home";
  const description = "We are products of a controlled society";
  return (
    <BrowserRouter>
     <MetaTags title={title} description={description} />
      <Routes>
        <Route exact path='/' element={<Homescreen></Homescreen>} />
        {/* <Route exact path='/' element={<Productscreen />} />
        <Route exact path='/product/:id' element={<SingleProduct updateCart={updateCart} />} />
        <Route exact path='/cart' element={<Cartscreen updateCart={updateCart} />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
