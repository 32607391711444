import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../logo.png'

export default function Navbar() {
    return (
        <header>
            <nav>
                <Link to='/' onClick={() => window.scrollTo(0, 0)}>
                    <img className="navbarlogo" alt="logo" src={logo}></img>
                </Link>
                {/* <div className='navs'>
                    <Link to="/shop" onClick={() => window.scrollTo(0, 0)}>
                        <button className='navbarbutton'>
                            Store
                        </button>
                    </Link>
                </div> */}
            </nav>
        </header>
    )
}
